export const pattaCategories: { [key: number]: string } = {
  19: "Residential",
  20: "Mix",
  21: "Institutional",
  22: "Commercial",
  24: "Industry",
  25: "Vacant",
  26: "69 A",
  27: "AnyOther",
  28: "Group Housing",
  29: "Religious and Charitable",
  30: "Farm House",
  31: "Tourism Unit",
  32:"Infra Project, Warehousing Project, Container Project",
}

export enum PattaPropertyCategories {
  Residential = 19,
  Mix = 20,
  Institutional = 21,
  Commercial = 22,
  Industry = 24,
  Vacant = 25,
  '69A' = 26,
  AnyOther = 27,
  GroupHousing = 28,
  ReligiousCharitable = 29,
  FarmHouse = 30,
  TourismUnit = 31,
  InfraWarehousingContainer = 32
}
